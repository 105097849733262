import { Modal } from "@mantine/core";
import { Dispatch, SetStateAction } from "react";
import { ProcessedSearch, RecentSearch } from "../lib/types";
import { Search } from "./AppShell";

const SearchModal = ({
  open,
  setOpen,
  processedSearches,
  setRecentSearches,
  setCompanies,
  setInvestors,
  companies,
  investors,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  processedSearches: ProcessedSearch[];
  setRecentSearches: Dispatch<SetStateAction<RecentSearch[]>>;
  companies: string[][];
  investors: string[][];
  setCompanies: Dispatch<SetStateAction<string[][]>>;
  setInvestors: Dispatch<SetStateAction<string[][]>>;
}) => (
  <Modal
    size="lg"
    opened={open}
    onClose={() => setOpen(false)}
    styles={{
      modal: {
        padding: "0px !important",
        margin: "0px !important",
      },
    }}
    withCloseButton={false}
  >
    <Search
      landing={true}
      processedSearches={processedSearches}
      setRecentSearches={setRecentSearches}
      setCompanies={setCompanies}
      setInvestors={setInvestors}
      companies={companies}
      investors={investors}
      shadow
      modal
    />
  </Modal>
);

export default SearchModal;

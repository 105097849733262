import { Dispatch, SetStateAction } from "react";
import Link from "next/link";
import { Divider, Menu } from "@mantine/core";
import {
  MdLogout,
  MdLogin,
  MdPlaylistAdd,
  MdOutlineEmail,
  MdDateRange,
} from "react-icons/md";
import { HiOutlineCalculator, HiOutlineLightBulb } from "react-icons/hi";
import { signOut, User } from "firebase/auth";
import { auth } from "../lib/firebase";
import { toast } from "react-hot-toast";
import { FiMenu } from "react-icons/fi";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import {
  RiArticleLine,
  RiArrowDownSFill,
  RiExchangeFundsFill,
} from "react-icons/ri";
import { useRouter } from "next/router";
import { FaHandshake } from "react-icons/fa";

const Navigation = ({
  setLoginModal,
  width,
  user,
}: {
  setLoginModal: Dispatch<SetStateAction<boolean>>;
  width: number;
  user: User;
}) => {
  const router = useRouter();

  return (
    <nav className="z-20 flex h-[8vh] items-center justify-between py-10 px-4 font-karla shadow-md sm:px-8 xl:px-12">
      <div className="flex items-center">
        <button
          onMouseDown={() => router.push("/")}
          className="mr-4 flex h-full min-w-[80px] cursor-pointer items-center justify-center text-2xl font-semibold  text-[#16126b] lg:text-[28px]"
        >
          10k reader
        </button>
        <div className="flex items-center">
          <button
            className="ml-6 hidden cursor-pointer items-center justify-start text-lg tracking-tight hover:underline hover:underline-offset-[3px] sm:flex"
            onMouseDown={() => router.push("/watchlist")}
          >
            My Watchlists
          </button>
          <Menu
            control={
              <div className="ml-6 hidden items-center justify-between text-lg sm:flex">
                Tools <RiArrowDownSFill size="24" />
              </div>
            }
            trigger="hover"
            styles={{
              body: {
                backgroundColor: "#fff",
                border: "none",
                width: "250px",
                display: "block",
              },
              item: { color: "#222A34" },
              label: { color: "#222A34" },
              itemHovered: {
                backgroundColor: "#ccc",
              },
            }}
          >
            <Menu.Item
              icon={<TbAdjustmentsHorizontal size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/screener")}
            >
              Stock Screener
            </Menu.Item>
            <Divider></Divider>
            <Menu.Item
              icon={<HiOutlineCalculator size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/compounding")}
            >
              Compounding Calculator
            </Menu.Item>
          </Menu>
          <Menu
            control={
              <div className="ml-6 hidden items-center justify-between text-lg sm:flex">
                Resources <RiArrowDownSFill size="24" />
              </div>
            }
            trigger="hover"
            styles={{
              body: {
                backgroundColor: "#fff",
                border: "none",
                width: "250px",
                display: "block",
              },
              item: { color: "#222A34" },
              label: { color: "#222A34" },
              itemHovered: {
                backgroundColor: "#ccc",
              },
            }}
          >
            <Menu.Item
              icon={<MdOutlineEmail size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/fund-letters")}
            >
              Hedge Fund Letters
            </Menu.Item>
            <Divider></Divider>
            <Menu.Item
              icon={<HiOutlineLightBulb size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/investors")}
            >
              Guru Investors
            </Menu.Item>
            <Divider></Divider>
            <Menu.Item
              icon={<MdDateRange size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/earnings")}
            >
              Earnings Calendar
            </Menu.Item>
            <Divider></Divider>
            <Menu.Item
              icon={<RiArticleLine size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/blog")}
            >
              Blog
            </Menu.Item>
          </Menu>
        </div>
      </div>
      <Menu
        placement="end"
        withArrow
        transition="scale-y"
        transitionDuration={250}
        transitionTimingFunction="ease"
        control={
          <button
            type="button"
            className="flex items-center justify-center rounded-md bg-pallet-200 px-4 py-1 text-white backdrop-blur-2xl hover:bg-pallet-200/90"
          >
            <FiMenu size="24" />
          </button>
        }
        styles={{
          body: {
            backgroundColor: "#5044B9",
            border: "none",
            width: "220px",
            display: "block",
          },
          item: { color: "#fff" },
          label: { color: "#fff" },
          itemHovered: {
            color: "#ccc",
          },
          arrow: {
            backgroundColor: "#5044B9",
            border: "#5044B9",
          },
        }}
      >
        {!user ? (
          <Menu.Item icon={<MdLogin />} onClick={() => setLoginModal(true)}>
            Login
          </Menu.Item>
        ) : (
          <Menu.Item
            icon={<MdLogout />}
            onClick={() =>
              signOut(auth).then(() =>
                toast.success("You have been signed out!")
              )
            }
          >
            Logout
          </Menu.Item>
        )}
        {width < 640 && <Divider />}
        {width < 640 && <Menu.Label>Tools</Menu.Label>}
        <Menu.Item icon={<MdPlaylistAdd />} className="sm:hidden">
          <Link href="/watchlist" passHref prefetch={false}>
            My Watchlists
          </Link>
        </Menu.Item>
        <Menu.Item icon={<TbAdjustmentsHorizontal />} className="sm:hidden">
          <Link href="/screener" passHref prefetch={false}>
            Stock Screener
          </Link>
        </Menu.Item>
        <Menu.Item icon={<HiOutlineCalculator />} className="sm:hidden">
          <Link href="/compounding" passHref prefetch={false}>
            Compounding Calculator
          </Link>
        </Menu.Item>
        <Menu.Item icon={<MdPlaylistAdd />} className="sm:hidden">
          <Link href="/fund-letters" passHref prefetch={false}>
            Hedge Fund Letters
          </Link>
        </Menu.Item>
        <Menu.Item icon={<HiOutlineLightBulb />} className="sm:hidden">
          <Link href="/investors" passHref prefetch={false}>
            Guru Investors
          </Link>
        </Menu.Item>
        <Menu.Item icon={<MdDateRange />} className="sm:hidden">
          <Link href="/earnings" passHref prefetch={false}>
            Earnings Calendar
          </Link>
        </Menu.Item>
        <Menu.Item icon={<RiArticleLine />} className="sm:hidden">
          <Link href="/blog" passHref prefetch={false}>
            Blog
          </Link>
        </Menu.Item>
      </Menu>
    </nav>
  );
};

export default Navigation;

import { useContext, useEffect, useRef, useState } from "react";
import { NextSeo } from "next-seo";
import Image, { StaticImageData } from "next/image";
import Head from "next/head";

// Auth
import { UserContext } from "../lib/context";

// Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Screenshots
import main_feature_1 from "../public/images/landing/main_feature_1.webp";
import main_feature_2 from "../public/images/landing/main_feature_2.webp";
import main_feature_3 from "../public/images/landing/main_feature_3.webp";
import main_feature_4 from "../public/images/landing/main_feature_4.webp";
import side_feature_1 from "../public/images/landing/side_feature_1.webp";
import side_feature_2 from "../public/images/landing/side_feature_2.webp";
import side_feature_3 from "../public/images/landing/side_feature_3.webp";
import side_feature_4 from "../public/images/landing/side_feature_4.webp";
import side_feature_5 from "../public/images/landing/side_feature_5.webp";

// Illustrations
import illustration_2 from "../public/images/landing/illustration_2.svg";
import card_1 from "../public/images/landing/card_1.webp";
import card_2 from "../public/images/landing/card_2.webp";
import bg_hero from "../public/images/landing/hero.webp";
import pie_chart from "../public/images/landing/piechart.svg";

// Animations
import { motion, useAnimation } from "framer-motion";
import Typewriter from "typewriter-effect";
import { useInView } from "react-intersection-observer";

// Icons
import { MdDateRange, MdOutlineEmail, MdPlaylistAdd } from "react-icons/md";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { HiOutlineCalculator, HiOutlineLightBulb } from "react-icons/hi";
import {
  RiArrowDownSFill,
  RiArticleLine,
  RiExchangeFundsFill,
  RiLinkedinBoxFill,
  RiRedditLine,
  RiTwitterLine,
} from "react-icons/ri";
import { FaHandshake, FaTiktok } from "react-icons/fa";

// Components
import Navigation from "../components/Navigation";
import SearchModal from "../components/SearchModal";
import { Search } from "../components/AppShell";
import { Divider, Menu, ScrollArea } from "@mantine/core";

// Routing
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";

export default function Home({
  setLoginModal,
  processedSearches,
  setRecentSearches,
  width,
  setCompanies,
  setInvestors,
  companies,
  investors,
}) {
  const router = useRouter();
  const user = useContext(UserContext);
  const slideRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const sideFeatures = [
    {
      image: side_feature_1,
      title: "Track Stocks",
      text: "Stay informed on the stocks you follow with customizable watchlists. Track the KPIs that matter most to you.",
      alt: "Watchlist Example: key performance indicators (KPI) table for software companies",
      icon: <MdPlaylistAdd size="26" className="mr-3" />,
    },
    {
      image: side_feature_2,
      title: "Stock Screener",
      text: "Choose from over 40 fundamental metrics and pre-built screeners to discover new investment opportunities.",
      alt: "Stock Screener Example: filtering by market cap, EPS Growth, and more.",
      icon: <TbAdjustmentsHorizontal size="26" className="mr-3" />,
    },
    {
      image: side_feature_3,
      title: "Fund Letters",
      text: "10kreader curates an archive of fund letters, articles, talks, and interviews from the top hedge funds in the world.",
      alt: "Hedge Fund Letters Page, compilations of the greatest investors like Warren Buffett, Charlie Munger, and Walter Schloss.",
      icon: <MdOutlineEmail size="26" className="mr-3 " />,
    },
    {
      image: side_feature_4,
      title: "Earnings Calendar",
      text: "Track companies who are expected to release earnings reports.",
      alt: "Earnings Calendar for the week starting August 22nd 2022.",
      icon: <MdDateRange size="26" className="mr-3" />,
    },
    {
      image: side_feature_5,
      title: "Compounding Calculator",
      text: "Visualize compounding and track your finances with beautiful and interactive charts.",
      alt: "Compounding Calculator page with $10,000 initial investment and $100 monthly contribution for 20 years",
      icon: <HiOutlineCalculator size="26" className="mr-3" />,
    },
  ];

  const [searchModal, setSearchModal] = useState(false);

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const pieChartVariants = {
    visible: { rotate: 0, transition: { duration: 1 } },
    hidden: { rotate: 180 },
  };

  const mainFeatures = [
    {
      image: main_feature_1,
      title: "Read and annotate filings",
      text: "Read company filings and transcripts for over 12,000 companies. Easily highlight, annotate, and save PDFs. Filings are available within seconds of release.",
      alt: "Elastic N.V. (NYSE:ESTC) Transcript Q4 2022, Highlighting and Annotating Feature",
      color: "#d0eaf6",
    },
    {
      image: main_feature_2,
      title: "Financials going back 40 years",
      text: "Easily analyze and visualize financials for over 35,000 companies. Financial statements are standardized, audited, and updated in real time.",
      alt: "Ten Years of Financials for Apple Inc. (NASDAQ:AAPL), Financial Statements Feature",
      color: "#fab68e",
    },
    {
      image: main_feature_3,
      title: "Visual insights",
      text: "Get the data and visuals you need to understand revenue streams, margin structure, financial health, cash flows, and much more.",
      alt: "Profit and Loss Breakdown 2021 for Apple Inc. (NASDAQ:AAPL), Visualizing Financials Feature",
      color: "#e3d9f7",
    },
    {
      image: main_feature_4,
      title: "Follow your favorite investors",
      text: "Easily analyze and visualize portfolios for over 9,000 investors. Study how portfolios and investments evolved over time with detailed statistics.",
      alt: "Berkshire Hathaway 13F Portfolio Holdings for Q2 2022, Investor Portfolios Feature",
      color: "#b3cafe",
    },
  ];

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <NextSeo
        title="10kreader - your all-in-one value investing resource"
        description="10kreader brings a kindle-like reading experience to the web for filings and transcripts and provides tools to easily analyze and visualize financials going back 40 years for over 40,000 stocks."
        openGraph={{
          url: `https://www.10kreader.com`,
          title: "10kreader - your all-in-one value investing resource",
          images: [
            {
              url: "https://www.10kreader.com/images/screenshots/home.webp",
              alt: "10kreader landing page",
              type: "image/webp",
            },
          ],
          site_name: "10kreader",
        }}
      />
      <SearchModal
        open={searchModal}
        setOpen={setSearchModal}
        companies={companies}
        investors={investors}
        setCompanies={setCompanies}
        setInvestors={setInvestors}
        processedSearches={processedSearches}
        setRecentSearches={setRecentSearches}
      />
      <main className="relative z-0 overflow-hidden font-roboto text-[#262C3A]">
        <div className="relative h-[100vh] min-h-[800px]">
          <Navigation setLoginModal={setLoginModal} width={width} user={user} />
          <div className="relative flex h-[92vh] flex-col items-center px-4 sm:px-8 xl:flex-row xl:items-start xl:px-12">
            <div className="w-full max-w-[640px] pt-[100px] sm:min-w-[550px] xl:mr-20 xl:w-1/2 xl:max-w-[700px] xl:pt-[140px]">
              <h1 className="mb-4 text-center font-title text-2xl md:text-[27.5px] lg:text-3xl xl:text-left">
                Your all-in-one value investing resource
              </h1>
              <Search
                landing={true}
                processedSearches={processedSearches}
                setRecentSearches={setRecentSearches}
                setCompanies={setCompanies}
                setInvestors={setInvestors}
                companies={companies}
                investors={investors}
                shadow
              />
            </div>
            <div className="mt-[100px] ml-[100px] mr-20 w-[90%] sm:ml-[80px] sm:w-[80%] md:ml-[40px] md:w-[70%] xl:w-[45%]">
              <div className="relative z-0 aspect-[1.6] h-auto w-full min-w-[100%] rounded-md bg-[#E9795D]/70 sm:aspect-[1.8]">
                <div className="absolute -top-[40px] right-[20px] bottom-0 my-auto aspect-[1.6] h-full w-auto rounded-md bg-white shadow-xl sm:top-0 sm:-left-[40px] sm:h-[90%]">
                  <Link href="/company/aapl" passHref prefetch={false}>
                    <a className="relative block h-full w-full min-w-[100%] rounded-md">
                      <Image
                        src={card_1}
                        loading="eager"
                        alt="Main Company Page for Microsoft with Profit and Loss Breakdown and Fundamental Metrics"
                        className="rounded-md"
                        layout="fill"
                        objectFit="cover"
                        objectPosition="center"
                      />
                    </a>
                  </Link>
                </div>
                <div className="absolute -right-[5%] -bottom-[10%] z-10 hidden aspect-[926/1470] h-auto w-[25%] rounded-md bg-white shadow-xl sm:block">
                  <Link href="/investor/0001067983" passHref prefetch={false}>
                    <a className="relative block h-full w-full min-w-[100%] rounded-md">
                      <Image
                        src={card_2}
                        alt="Warren Buffett Portfolio Statistics"
                        className="rounded-md"
                        layout="fill"
                        objectFit="cover"
                        objectPosition="center"
                      />
                    </a>
                  </Link>
                </div>
                <div className="absolute -right-[10%] -bottom-[20%] hidden aspect-[926/1470] h-auto w-[22%] rounded-md bg-[#4354F2] sm:block"></div>
              </div>
            </div>
          </div>
          <div className="absolute inset-0 -z-10 h-full w-full">
            <div className="relative h-full w-full">
              <Image
                src={bg_hero}
                layout="fill"
                quality={100}
                alt="Background Image"
                objectFit="cover"
                objectPosition="center"
                loading="eager"
              />
            </div>
          </div>
        </div>
        <div className=" bg-white py-8 px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32">
          <h2 className="flex flex-col text-center text-xl md:text-[26px] lg:mb-10 lg:text-[32px] 2xl:text-5xl">
            <span className="mb-4 mt-10 font-bold">
              Free access to quality data and tools
            </span>
            <span className="flex items-center justify-center font-light">
              for the modern{" "}
              <span className="ml-2 flex w-[120px] items-center justify-start md:w-[140px] lg:w-[180px] xl:w-[220px]">
                <Typewriter
                  options={{
                    strings: ["investor", "analyst", "researcher"],
                    autoStart: true,
                    loop: true,
                    delay: 50,
                    deleteSpeed: 40,
                    // @ts-ignore
                    pauseFor: 600,
                  }}
                />
              </span>
            </span>
          </h2>
          {mainFeatures.map(({ image, title, text, alt, color }, index) => (
            <MainFeatureHighlight
              key={title}
              direction={index % 2 ? true : false}
              title={title}
              text={text}
              image={image}
              color={color}
              alt={alt}
              index={index}
            />
          ))}
        </div>
        <div
          className="relative z-0 block h-full min-w-[100%] bg-[#16055A] px-4 py-12 sm:px-8 md:py-20 xl:px-12 xl:pt-20 xl:pb-32"
          ref={ref}
        >
          <motion.div
            className="absolute -right-[20%] -top-[10%] -z-10 aspect-[1] h-auto w-[40%] lg:-top-[15%] xl:-top-[20%]"
            animate={controls}
            initial="hidden"
            variants={pieChartVariants}
          >
            <Image
              src={pie_chart}
              alt="Pie Chart"
              layout="fill"
              priority
              objectFit="contain"
              objectPosition="center"
            />
          </motion.div>
          <h2 className="flex flex-col text-center text-xl tracking-wide text-white md:text-[26px] lg:mb-10 lg:text-[32px] 2xl:text-5xl">
            <span className="mb-4 font-bold">
              The toolkit you need to discover and track
            </span>
            <span className="font-light">your stocks and investors</span>
          </h2>
          <div className="mx-auto flex max-w-[1300px] flex-col py-4 lg:flex-row">
            <ScrollArea
              type="always"
              className="mx-auto mb-4 flex w-full max-w-[520px] text-lg text-white lg:w-[40%] lg:max-w-none"
              scrollbarSize={5}
              scrollHideDelay={0}
              styles={{
                thumb: {
                  backgroundColor: "#fff",
                },
                scrollbar: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <div className="flex lg:flex-col">
                {sideFeatures.map((feature, index) => (
                  <SideFeatureSelect
                    index={index}
                    isActive={index === activeSlide}
                    key={feature.title}
                    icon={feature.icon}
                    title={feature.title}
                    text={feature.text}
                    setActiveTab={() => slideRef?.current?.slickGoTo(index)}
                  />
                ))}
              </div>
            </ScrollArea>
            <Slider
              speed={800}
              autoplaySpeed={4000}
              slidesToShow={1}
              arrows={false}
              dots={false}
              infinite
              autoplay
              className="relative aspect-[1.5] h-auto w-full overflow-hidden rounded-md md:mt-0 lg:w-[60%] lg:max-w-[800px]"
              ref={slideRef}
              beforeChange={(current, next) => setActiveSlide(next)}
              afterChange={(current) => setActiveSlide(current)}
            >
              {sideFeatures.map(({ image, title, alt }) => (
                <div
                  className="relative aspect-[1.5] h-auto w-[60%] min-w-[100%] overflow-hidden rounded-md shadow-xl lg:max-w-[800px]"
                  key={title}
                >
                  <Image
                    src={image}
                    placeholder="blur"
                    className="rounded-md"
                    alt={alt}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* <div className="relative z-0 flex w-full flex-col justify-between bg-white pb-20 pt-16">
          <h2 className="mb-10 flex flex-col text-center text-2xl font-bold sm:text-3xl lg:text-4xl xl:text-5xl">
            Growing with the community
          </h2>
          <div className="absolute left-0 bottom-0 -z-10 h-[150px] w-[30%] bg-pallet-500/30"></div>
          <div className="absolute right-0 top-[10%] -z-10 h-[150px] w-[30%] bg-pallet-500/30"></div>
          <ScrollArea scrollbarSize={5} scrollHideDelay={500}>
            <div className="flex items-center justify-between overflow-auto px-4 sm:px-8 xl:px-12">
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className="mr-12 mb-2 h-[250px] min-w-[300px] rounded-md bg-pallet-500 px-4 py-4 shadow-md sm:px-8"
                >
                  <ScrollArea
                    scrollbarSize={5}
                    scrollHideDelay={500}
                    className="h-[190px]"
                  >
                    <div className="flex text-center">
                      <RiDoubleQuotesL size="24" className="min-w-[20px]" />
                      {review.review}
                    </div>
                  </ScrollArea>
                  <Link href={review.link} passHref>
                    <a
                      className="flex cursor-pointer items-center justify-end pt-3 text-pallet-200 hover:underline hover:underline-offset-2"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {review.person}
                    </a>
                  </Link>
                </div>
              ))}
            </div>
          </ScrollArea>
        </div> */}
        <div className="relative z-10 h-[300px] bg-white px-4 pt-10 sm:px-8 md:h-[400px] lg:h-[500px] xl:h-[600px] xl:px-12">
          <div className="mx-auto w-full max-w-[640px] pt-[10px]">
            <h1 className="mb-5 text-center font-title text-2xl md:text-3xl md:text-[32px]">
              Search for any companies, investors, or ETFs
            </h1>
            <Search
              landing
              shadow
              processedSearches={processedSearches}
              setRecentSearches={setRecentSearches}
              setCompanies={setCompanies}
              setInvestors={setInvestors}
              companies={companies}
              investors={investors}
              disabled={width <= 1024}
              setOpenSearchModal={setSearchModal}
              bottom
            />
          </div>
          <Illustration />
        </div>
        <Footer router={router} />
      </main>
    </>
  );
}

const Illustration = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const barChartVariants = {
    visible: { opacity: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0 },
  };

  return (
    <motion.div
      className="absolute bottom-0 right-0 left-0 -z-10 aspect-[1801/692] h-auto overflow-hidden"
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={barChartVariants}
    >
      <div className="relative block h-full w-full min-w-[100%]">
        <Image
          src={illustration_2}
          alt="Bar Chart"
          layout="fill"
          objectFit="contain"
          objectPosition="center"
        />
      </div>
    </motion.div>
  );
};

const SideFeatureSelect = ({
  isActive,
  icon,
  title,
  text,
  setActiveTab,
  index,
}: {
  isActive: boolean;
  icon: JSX.Element;
  title: string;
  text: string;
  setActiveTab: () => void;
  index: number;
}) => {
  let height = "";

  switch (title) {
    case "Track Stocks":
      height = "w-[170px]";
      break;
    case "Stock Screener":
      height = "w-[190px]";
      break;
    case "Fund Letters":
      height = "w-[170px]";
      break;
    case "Earnings Calendar":
      height = "w-[220px]";
      break;
    default:
      height = "w-[290px]";
      break;
  }

  return (
    <>
      <div onMouseDown={setActiveTab} className={`my-4 ${height} lg:w-auto`}>
        <h4
          className={`mb-2 flex cursor-pointer font-title font-[600] tracking-wide lg:text-xl ${
            isActive ? "" : "text-gray-400"
          }`}
          id={`feature-${index}`}
        >
          {icon}
          {title}
        </h4>
        {isActive && (
          <h5 className="hidden w-[80%] font-karla text-white/60 lg:block">
            {text}
          </h5>
        )}
      </div>
      <div className="hidden h-[2px] w-[90%] bg-gray-200/30 lg:block"></div>
    </>
  );
};

const MainFeatureHighlight = ({
  direction,
  title,
  text,
  image,
  color,
  alt,
  index,
}: {
  direction?: boolean;
  title: string;
  text: string;
  image: StaticImageData;
  color: string;
  alt: string;
  index: number;
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const imageVariants = {
    visible: { x: 0, transition: { duration: 0.8 } },
    hidden: { x: direction ? 500 : -500 },
  };

  const textVariants = {
    visible: { opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0 },
  };

  return (
    <div
      className={`relative z-0 my-[40px] mx-auto max-w-[1200px] md:my-20 lg:my-28 xl:my-32 ${
        direction ? "md:flex-row" : "md:flex-row-reverse"
      } flex flex-col items-center justify-center`}
      ref={ref}
    >
      <motion.div
        className={`my-4 w-full text-center text-[17px] md:my-0 md:w-[42%] md:text-left lg:w-1/2 lg:text-lg xl:text-[20px] ${
          direction ? "md:pr-12 lg:pr-20" : "md:pl-12 lg:pl-20"
        }`}
        animate={controls}
        initial="hidden"
        variants={textVariants}
      >
        <h3 className="mb-4 font-title text-[22px] leading-[35px] lg:text-3xl xl:text-4xl xl:leading-[42px]">
          {title}
        </h3>
        <h4 className="mb-6 font-karla leading-[30px] text-[#262e3a]/70 md:mb-0 md:leading-[35px]">
          {text}
        </h4>
      </motion.div>
      <motion.div
        className="custom-shadow relative block aspect-[1.5] h-auto w-full min-w-[100%] rounded-md bg-white md:w-[55%] md:min-w-[55%] lg:w-1/2 lg:min-w-[50%]"
        animate={controls}
        initial="hidden"
        variants={imageVariants}
      >
        <Image
          src={image}
          className="rounded-md"
          alt={alt}
          placeholder="blur"
          priority={index === 0}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />
        <div
          className={`absolute ${
            direction ? "left-6 lg:left-10" : "right-6 lg:right-10"
          } top-6 -z-10 hidden aspect-[1.5] h-auto w-full rounded-md md:block lg:top-10 `}
          style={{
            backgroundColor: color,
          }}
        ></div>
      </motion.div>
    </div>
  );
};

const Footer = ({ router }: { router: NextRouter }) => (
  <div className="flex h-full flex-col items-center bg-[#1A0447] py-8 px-4 text-white shadow-md sm:px-8 xl:px-12">
    <div className="mb-5 flex w-full flex-col items-center justify-between sm:flex-row">
      <span className="text-wide mb-4 text-center text-lg sm:mb-0 sm:text-left">
        2022 © 10kreader |{" "}
        <span>
          Data from{" "}
          <a
            href="https://financialmodelingprep.com/developer/docs/"
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="mt-1 cursor-pointer tracking-wide hover:underline hover:underline-offset-[3px]"
            style={{
              textDecorationThickness: "0.6px",
            }}
          >
            FMP
          </a>{" "}
          and{" "}
          <a
            href="https://www.sec.gov/edgar/sec-api-documentation"
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="mt-1 cursor-pointer tracking-wide hover:underline hover:underline-offset-[3px]"
            style={{
              textDecorationThickness: "0.6px",
            }}
          >
            SEC
          </a>
        </span>
      </span>
      <div className="flex flex-col items-center font-light sm:items-end xl:flex-row xl:items-center xl:justify-end">
        <div className="flex flex-col items-center sm:flex-row">
          <button
            className="flex cursor-pointer items-center justify-start text-lg tracking-tight text-white hover:underline hover:underline-offset-[3px] sm:ml-6 sm:min-w-[110px]"
            onMouseDown={() => router.push("/watchlist")}
          >
            My Watchlists
          </button>
          <button
            className="mt-0.5 flex cursor-pointer items-center justify-start text-lg tracking-tight text-white hover:underline hover:underline-offset-[3px] sm:ml-6 sm:hidden"
            onMouseDown={() => router.push("/screener")}
          >
            Stock Screener
          </button>
          <button
            className="mt-0.5 flex cursor-pointer items-center justify-start text-lg tracking-tight text-white hover:underline hover:underline-offset-[3px] sm:ml-6 sm:hidden"
            onMouseDown={() => router.push("/compounding")}
          >
            Compounding Calculator
          </button>
          <button
            className="mt-0.5 flex cursor-pointer items-center justify-start text-lg tracking-tight text-white hover:underline hover:underline-offset-[3px] sm:ml-6 sm:hidden"
            onMouseDown={() => router.push("/fund-letters")}
          >
            Hedge Fund Letters
          </button>
          <button
            className="mt-0.5 flex cursor-pointer items-center justify-start text-lg tracking-tight text-white hover:underline hover:underline-offset-[3px] sm:ml-6 sm:hidden"
            onMouseDown={() => router.push("/investors")}
          >
            Top Investors
          </button>
          <button
            className="mt-0.5 flex cursor-pointer items-center justify-start text-lg tracking-tight text-white hover:underline hover:underline-offset-[3px] sm:ml-6 sm:hidden"
            onMouseDown={() => router.push("/earnings")}
          >
            Earnings Calendar
          </button>
          <button
            className="mt-0.5 flex cursor-pointer items-center justify-start text-lg tracking-tight text-white hover:underline hover:underline-offset-[3px] sm:ml-6 sm:hidden"
            onMouseDown={() => router.push("/blog")}
          >
            Blog
          </button>
          <Menu
            control={
              <div className="ml-3 hidden items-center justify-between text-lg sm:flex">
                Tools <RiArrowDownSFill size="24" />
              </div>
            }
            trigger="hover"
            styles={{
              body: {
                backgroundColor: "#fff",
                border: "none",
                width: "250px",
                display: "block",
              },
              item: { color: "#222A34" },
              label: { color: "#222A34" },
              itemHovered: {
                backgroundColor: "#ccc",
              },
            }}
          >
            <Menu.Item
              icon={<TbAdjustmentsHorizontal size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/screener")}
            >
              Stock Screener
            </Menu.Item>
            <Divider></Divider>
            <Menu.Item
              icon={<HiOutlineCalculator size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/compounding")}
            >
              Compounding Calculator
            </Menu.Item>
          </Menu>
          <Menu
            control={
              <div className="ml-3 hidden items-center justify-between text-lg sm:flex">
                Resources <RiArrowDownSFill size="24" />
              </div>
            }
            trigger="hover"
            styles={{
              body: {
                backgroundColor: "#fff",
                border: "none",
                width: "250px",
                display: "block",
              },
              item: { color: "#222A34" },
              label: { color: "#222A34" },
              itemHovered: {
                backgroundColor: "#ccc",
              },
            }}
          >
            <Menu.Item
              icon={<MdOutlineEmail size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/fund-letters")}
            >
              Hedge Fund Letters
            </Menu.Item>
            <Divider></Divider>
            <Menu.Item
              icon={<HiOutlineLightBulb size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/investors")}
            >
              Top Investors
            </Menu.Item>
            <Divider></Divider>
            <Menu.Item
              icon={<MdDateRange size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/earnings")}
            >
              Earnings Calendar
            </Menu.Item>
            <Divider></Divider>
            <Menu.Item
              icon={<RiArticleLine size="18" />}
              className="hover:bg-gray-100"
              onMouseDown={() => router.push("/blog")}
            >
              Blog
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </div>
    <div className="flex w-full flex-col items-center justify-between sm:flex-row sm:items-start">
      <Link
        href={`https://www.buymeacoffee.com/kevingbojan`}
        target="_blank"
        rel="noopener noreferrer nofollow"
        prefetch={false}
      >
        <a target="_blank">
          <Image
            src="/images/buy-me-a-coffee.png"
            alt="Buy Me A Coffee"
            height={35}
            quality={100}
            width={126.583}
          />
        </a>
      </Link>
      <div className="mt-4 flex items-center sm:mt-0">
        <Link
          href="https://www.linkedin.com/company/10kreader"
          passHref
          prefetch={false}
        >
          <a target="_blank" className="mr-3">
            <RiLinkedinBoxFill size="32" />
          </a>
        </Link>
        <Link href="https://twitter.com/10kreader" passHref prefetch={false}>
          <a target="_blank" className="mr-3">
            <RiTwitterLine size="32" />
          </a>
        </Link>
        <Link
          href="https://www.reddit.com/user/azurecap"
          passHref
          prefetch={false}
        >
          <a target="_blank">
            <RiRedditLine size="32" className="mr-3" />
          </a>
        </Link>
        <Link
          href="https://www.tiktok.com/@10kreader"
          passHref
          prefetch={false}
        >
          <a target="_blank">
            <FaTiktok size="30" />
          </a>
        </Link>
      </div>
    </div>
  </div>
);

// const reviews = [
//   {
//     review:
//       "I've been using your site on a daily basis. I love it. It's thoughtfully designed and exceptional! Thank you!!!",
//     person: "@Valtyr8",
//     link: "https://www.reddit.com/r/ValueInvesting/comments/vv5h5d/update_post_more_free_resources_watchlist_dcf/",
//   },
//   // {
//   //   review: "Man, hats off to you. I'm very new to investing, and your platform as easily been the easiest to navigate and understand as a beginner.",
//   //   person: "@MaxToMillions",
//   //   link: "https://www.reddit.com/r/ValueInvesting/comments/v9xuoj/free_resource_annotate_filings_transcripts/",
//   // },
//   // {
//   //   review:
//   //     "I love using the site (daily) and finding your improvements on the things we've discussed. It's like a treasure hunt of excellent development.",
//   //   person: "@Valtyr8",
//   //   link: "https://www.reddit.com/r/ValueInvesting/comments/wdiase/i_created_a_compilation_of_the_greatest_investors/",
//   // },
//   {
//     review:
//       "I absolutely love this site! Since you first posted it a few months back, I've used it religiously. It's so helpful to have literally all the information needed in one concentrated place at a price I can't beat (I really appreciate you keeping it free). I've also noticed that you've been putting a ton of work and updates into the site. It seems that every time I log in (which is nearly daily) a new feature or an additional bit of information has been added. So thank you from a loyal user!",
//     person: "@MaxToMillions",
//     link: "https://www.reddit.com/r/ValueInvesting/comments/wdiase/i_created_a_compilation_of_the_greatest_investors/",
//   },
//   {
//     review:
//       "Great work! I use your website very often recently. The database is such a high quality work that I haven't seen on other websites. And all of that for free.",
//     person: "@tomlerr",
//     link: "https://www.reddit.com/r/ValueInvesting/comments/wdiase/i_created_a_compilation_of_the_greatest_investors/",
//   },
//   {
//     review:
//       "I am blown away. This is a BEAUTIFUL site and it aggregates so much data that I typically use 3-4 sites for (including QuiverQuant, Shift, and Finviz). ",
//     person: "@BlinkpadProfiles",
//     link: "https://www.reddit.com/r/reactjs/comments/wcm60c/i_built_an_app_to_study_companies_follow/",
//   },
//   {
//     review:
//       "I love the overall experience, lots of information and the design is clean.",
//     person: "@CompetitiveAd3294",
//     link: "https://www.reddit.com/r/webdev/comments/vpu1tx/built_an_app_to_study_businesses_visualize/",
//   },
// ];
